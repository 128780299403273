/*jshint esversion: 6 */
(function () {
    "use strict";
    $(function () {
        $(document).ready(function () {
            setTimeout(function () {
                $(".loader").fadeOut();
            }, 3000);

            $(".section-toggle").click(function (e) {
                e.preventDefault();

                var $id = $(this).attr("href").substr(1);
                var $currentSection = $("#" + $id);
                var $currentSectionLeft = -$currentSection.index() * 100 + "%";
                $("a.active").removeClass("active");
                $('[href="' + $(this).attr("href") + '"]').toggleClass(
                    "active"
                );
                //jesli header nie jest otwarty
                if (!$("header").hasClass("active")) {
                    $("section.active").removeClass("active");
                    $currentSection.toggleClass("active");
                    $("main").css({
                        "transition-duration": "0s",
                        transform: "translateX(" + $currentSectionLeft + ")",
                    });

                    $("header")
                        .addClass("active")
                        .find(">h1")
                        .addClass("hide-sections");
                    $(".hide-sections").click(function () {
                        $("header")
                            .removeClass("active")
                            .find(">h1")
                            .removeClass("hide-sections");
                        $("a.active, section.active").removeClass("active");
                    });
                } else {
                    //header jest otwarty

                    $("main").css({
                        "transition-duration": "1s",
                        transform: "translateX(" + $currentSectionLeft + ")",
                    });
                }
            });
        });

        $(window).on("load", function () {});
    });
})(jQuery);
